import React from 'react';
import { PERMISSIONS } from '../config/permissions';

const SilentRenew = React.lazy(() => import('../components/auth/authSilentRenew'));
const Logout = React.lazy(() => import('../components/auth/Logout'));
const LogoutCallback = React.lazy(() => import('../components/auth/LogoutCallback'));
const Callback = React.lazy(() => import('../components/auth/authCallback'));

const unAuthorised = React.lazy(() => import('../components/AuthPages/UnAuthorized'));
const Forbidden = React.lazy(() => import('../components/AuthPages/Forbidden'));
const PostLogout = React.lazy(() => import('../components/AuthPages/PostLogout'));

const VesselList = React.lazy(() => import('../components/vesselList'));
const VesselLocator = React.lazy(() => import('../components/vesselLocator'));
const VesselDetails = React.lazy(() => import('../components/vesselDetails'));
const Policies = React.lazy(() => import('../components/userManagement/policies'));
const UserRoles = React.lazy(() => import('../components/userManagement/userRoles'));
const Users = React.lazy(() => import('../components/userManagement/users'));
const Fleets = React.lazy(() => import('../components/userManagement/fleets'));
const AccountingAdmin = React.lazy(() => import('../components/accountingAdmin'));
const AccountingTypes = React.lazy(() => import('../components/accountingReportTypes'));
const SpMigrationsAdmin = React.lazy(() => import('../components/SpMigrationsAdmin'));
const UserManual = React.lazy(() => import('../components/userManual'));

const routes = {
  publicRoutes: [
    { key: 1, path: '/authentication/callback', component: Callback },
    { key: 2, path: '/authentication/silent_callback', component: SilentRenew },
    { key: 3, path: '/logout', component: Logout },
    { key: 4, path: '/logout/callback', component: LogoutCallback },
    { key: 5, path: '/post-logout', component: PostLogout },
    { key: 6, path: '/unauthorized', component: unAuthorised },
    { key: 7, path: '/forbidden', component: Forbidden }
  ],
  secureRoutes: [
    { key: 10, path: '/vessel-list', component: VesselList, hasNav: true },
    { key: 11, path: '/vessel-locator', component: VesselLocator, hasNav: true, isFullScreen: true },
    {
      key: 13,
      path: '/vessel-details/:vesselId',
      component: VesselDetails,
      hasNav: true,
      hasStickyContent: true,
      permissions: [
        PERMISSIONS.DASHBOARD.VIEW, PERMISSIONS.PARTICULARS.VIEW, PERMISSIONS.VESSEL_PLAN.VIEW,
        PERMISSIONS.VESSEL_REPORT.VIEW, PERMISSIONS.GEOLOCATOR.VIEW,
        PERMISSIONS.ENGINE_PERFORMANCE.VIEW, PERMISSIONS.VESSEL_INSPECTION.VIEW,
        PERMISSIONS.DEFECT_LIST.VIEW, PERMISSIONS.CREW_LIST.VIEW, PERMISSIONS.CERTIFICATES.VIEW,
        PERMISSIONS.INTERNAL_AUDIT.VIEW, PERMISSIONS.ACCOUNTING_REPORT.VIEW,
        PERMISSIONS.BUNKERING_REPORT.VIEW, PERMISSIONS.PURCHASE.VIEW, PERMISSIONS.PMS.VIEW,
        PERMISSIONS.VESSEL_EXPENSE.VIEW, PERMISSIONS.LUBE_OIL_ANALYSIS_REPORT.VIEW
      ]
    },
    { key: 14, path: '/user-management/policies', component: Policies, hasNav: true, permissions: [PERMISSIONS.POLICIES.VIEW] },
    { key: 15, path: '/user-management/roles', component: UserRoles, hasNav: true, permissions: [PERMISSIONS.ROLES.VIEW] },
    { key: 16, path: '/user-management/users', component: Users, hasNav: true, permissions: [PERMISSIONS.USERS.VIEW] },
    { key: 17, path: '/user-management/fleets', component: Fleets, hasNav: true, permissions: [PERMISSIONS.FLEETS.VIEW] },
    { key: 18, path: '/accounting', component: AccountingAdmin, hasNav: true, permissions: [PERMISSIONS.ACCOUNTING_ADMIN.VIEW] },
    { key: 19, path: '/accounting-types', component: AccountingTypes, hasNav: true, permissions: [PERMISSIONS.ACCOUNTING_TYPES.VIEW] },
    { key: 20, path: '/v3-migrations', component: SpMigrationsAdmin, hasNav: true, permissions: [PERMISSIONS.SP_V3_MIGRATIONS.VIEW] },
    { key: 21, path: '/help', component: UserManual, hasNav: true }
  ]
};

export default routes;
