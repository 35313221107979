export const apiurl = {
  getVessels: '/vessel/list',
  getBiToken: '/GetToken',
  uploadVesselPlan: 'vessel/vesselplans/upload',
  vesselDetails: '/vessel/details',
  getVesselPlans: '/vessel/vesselplans/list',
  getDefectList: '/defect/list',
  getDefectStatusList: '/defect/status',
  removeVesselPlan: '/vessel/vesselplans/remove',
  exportVesselList: '/vessel/export',
  getVoyageReports: '/vesselvoyage/report',
  getVoyageReportTypes: '/vesselvoyage/reporttypes',
  getVesselPlanCategories: '/vessel/vesselplans/documenttypes',
  getVesselInpsectionList: '/vesselinspection/list',
  getOpenDefectCounts: '/defect/count',
  getLatestNoonReport: '/vesselvoyage/latestreport',
  exportVesselInspection: '/vesselinspection/export',
  addUserRoles: '/roles/add',
  listUserRoles: '/roles/list',
  editUserRole: '/roles/update',
  deleteUserRole: '/roles/remove',
  addPolicies: '/policies/add',
  listPolicies: '/policies/list',
  editPolicy: '/policies/update',
  deletePolicy: '/policies/remove',
  addFleet: '/fleets/add',
  listFleets: '/fleets/list',
  editFleet: '/fleets/update',
  deleteFleet: '/fleets/remove',
  listAudits: '/vesselaudit/list',
  addUser: '/users/add',
  listUsers: '/users/list',
  editUser: '/users/update',
  deleteUser: '/users/remove',
  uploadAccounting: '/documentmanager/upload',
  getAccountingTypes: '/documentmanager/documenttypes',
  listAccoutings: '/documentmanager/list',
  exportAudit: '/vesselaudit/export',
  deleteAccounting: '/documentmanager/remove',
  listBunkerings: '/vesselbunkering/list',
  listBunkeringSurverys: 'vesselbunkering/surveylist',
  exportVesselReports: '/vesselvoyage/export',
  addDocumentTypes: '/documentmanager/documenttypes/add',
  editDocumentTypes: '/documentmanager/documenttypes/update',
  deleteDocumentType: '/documentmanager/documenttypes/remove',
  authenticateUser: '/login',
  checkUserEmailValid: '/users/emailvalidation',
  toggleUserStatus: '/users/enableordisable',
  getCrewList: '/crew/list',
  getVesselLocations: '/vessel/locationinfo',
  exportCrewList: '/crew/export',
  getVoyageReportNoPaging: '/vesselvoyage/reportlist',
  getCertificateList: '/certificate/list',
  getCertificateHistory: '/certificate/history',
  getAllPermissions: '/permission/list',
  getCertificateSurveys: '/certificate/Surveylist',
  getCrewCount: '/crew/dashboard',
  getCertificateCount: '/certificate/dashboard',
  getEnginePerformance: '/engineperformance/list',
  listCertificateDeviations: '/certificate/openDeviation',
  listOpenCoc: '/certificate/openCOC',
  listPurchaseOrders: '/purchaseOrder/list',
  listPmsJobs: '/pms/list',
  listPmsDepartments: '/pms/GetPMSDepartment',
  listPmsStatus: '/pms/GetPMSStatus',
  listSpareReport: '/pms/SpareReport',
  listVesselManagement: '/vessel/GetManagementType',
  listPmsHistory: '/pms/historylist',
  getVesselTypes: '/vessel/GetVesselType',
  exportDefectDetails: '/defect/HullMachineryexport',
  exportPmsHistoryDetails: '/pms/historyreportview',
  exportNearMissReport: '/defect/IncidentNearMissexport',
  exportAccidentReport: '/defect/IncidentAccidentExport',
  exportShipnetDefectReport: '/defect/ShipnetOneDefectExport',
  exportFreeFlowReport: '/defect/FreeFlowDefectExport',
  updateVesselV3Status: '/vessel/update',
  getPurchaseDetails: '/purchaseOrder/details',
  getPurchaseLog: '/purchaseOrder/transactionLogList',
  getPurchaseItemsList: '/purchaseOrder/itemList',
  uploadSupportingDocs: '/documentmanager/uploadSupportingDocs',
  getFileDownload: '/documentmanager/download',
  exportPmsList: '/pms/export',
  getMessage: '/settings/CrpDownTime',
  getPmsClass: '/pms/GetPMSClass',
  exportVesselListexcel: '/vessel/excelexport',
  getRobList: '/vesselvoyage/ROBList/dashboard',
  getConsumptionList: '/vesselvoyage/Consumptionlist/dashboard',
  getVesselExpenseList: '/vesselexpense/list',
  getVesselExpenseDetails: '/vesselexpense/details',
  getCumulativeExpense: '/vesselexpense/cumulativeDetails',
  exportcrewExcel: '/crew/excelExport',
  exportvesselExpense: '/vesselexpense/export',
  exportvesseExpenseDetails: '/vesselexpense/exportDetails',
  getfilesizeLimit: '/settings/FileSizeLimit',
  getdefectReportType: '/defect/reportTypes',
  getdefectauditType: '/defect/reportAuditTypes',
  getdefectReportlist: '/defect/showReportList',
  getDefectSubType: '/defect/reportAuditSubTypes',
  exportPurchase: '/purchase/export',
  exportCertificates: '/certificate/excelExport',
  getAisVesselLocation: '/vessel/aisLocationInfo',
  getPmsComponents: '/pms/GetPMSComponent',
  getUserManual: '/usermanual/getActiveUserManual',
  uploadUserManual: '/usermanual/upload',
  exportSparesReport: '/pms/SpareReport/export',
  getVesselVoyageDetail: '/vesselvoyage/details',
  exportVesselDetailReports: 'vesselvoyage/detailsexport',
  getPmsSpareSubClass: '/pms/GetPMSSpareSubClass',
  exportVesselParticularList: '/vessel/vesselParticularsExport',
  getLubeOilAnalysisReport: '/lubeOilAnalysis/list',
  ciiMsgUrl: 'settings/CIIMessage'
};
