import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT, DEFAULT_DATES, MIN_YEAR_IN_RANGE } from '../config/constants';

export const cleanFileName = (filename) => {
  const array = filename.split('.');
  array.pop();
  return array.join();
};

export const downloadBlob = async (response, fileName, options = {}) => {
  const { isOpenNewTab = false, type } = options;

  if (isOpenNewTab) {
    const url = window.URL.createObjectURL(new Blob([response], { type }));
    window.open(url);
  } else {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('style', 'display: none');
    link.setAttribute('download', fileName); // or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const downloadImage = (href, fileName) => {
  let link;
  return fetch(href, {
    method: 'GET'
  })
    .then(async (response) => {
      const buffer = await response.arrayBuffer();
      const url = window.URL.createObjectURL(new Blob([buffer]));
      link = document.createElement('a');
      link.href = url;
      link.setAttribute('style', 'display: none');
      link.setAttribute('download', fileName); // or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      if (link) {
        link.remove();
      }
    });
};

export const getYearsList = () => {
  const currentYear = moment().year();
  const array = [];
  for (let i = MIN_YEAR_IN_RANGE; i <= currentYear; i += 1) {
    array.push(i);
  }
  return array;
};

export const convertDMSToDD = ([degrees, minutes, seconds, direction]) => {
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  } // Don't do anything for N or E
  return dd;
};

export const parseDMS = (latitude, longitude) => {
  /* eslint-disable-next-line */
  const lat = convertDMSToDD(latitude.split(/[^\d\w\.]+/));
  /* eslint-disable-next-line */
  const lng = convertDMSToDD(longitude.split(/[^\d\w\.]+/));

  return {
    lat,
    lng
  };
};

export const containsAlphabet = async (value) => {
  return /.*[a-zA-Z].*/.test(value);
};

export const hasPermission = (permission, userPermissions) => {
  return userPermissions.includes(permission);
};

export const hasAnyPermissions = (permissions = [], userPermissions) => {
  return permissions.some((permission) => userPermissions.includes(permission));
};

export const checkAndFormatDate = (date, options = {}) => {
  const { showTime = false } = options;
  const format = showTime ? DATE_TIME_FORMAT : DATE_FORMAT;
  return date && !DEFAULT_DATES.some((defaultDate) => (
    moment(defaultDate).startOf('day').isSame(moment(date).startOf('day'))
  ))
    ? moment.utc(date).format(format)
    : null;
};

export const formatCount = (count) => {
  return Number(count) > 9 || Number(count) < 1 ? count : `0${count}`;
};

export const strictEmailValidation = (email) => {
  const regex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9]))+((\.[a-zA-Z]{2,})*)$/;
  if (regex.test(email)) {
    return true;
  }
  return false;
};

export function getLeafNodes(items, parentLabels = []) {
  let result = [];
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    const currentPath = [...parentLabels, item.value];
    if (item.options?.length) {
      // Recursively process child nodes with updated parent path
      result = result.concat(getLeafNodes(item.options, currentPath));
    } else {
      // Add leaf node with parent path reference
      result.push({ ...item, parents: currentPath });
    }
  }
  return result;
}

export function convertToStandardTreeFormat(data, labelField, valueField, optionsField) {
  return data?.map((item) => {
    const convertedItem = {
      label: item[labelField],
      value: item[valueField]
    };

    if (item[optionsField]) {
      convertedItem.options = convertToStandardTreeFormat(
        item[optionsField], labelField, valueField, optionsField
      );
    }

    return convertedItem;
  });
}

const setMatchedForChildren = (node) => {
  // Recursively mark all nodes with searchMatched: true
  return {
    ...node,
    searchMatched: true,
    options: node.options?.map(setMatchedForChildren) || []
  };
};

const dfs = (root, search = '') => {
  if (!root) return null;

  const isMatch = root.label?.toLowerCase().includes(search.toLowerCase());

  const matchedChildOptions = root.options?.reduce((acc, option) => {
    const matched = dfs(option, search);
    if (matched) acc.push(matched);
    return acc;
  }, []) || [];

  if (isMatch || matchedChildOptions.length) {
    return {
      ...root,
      searchMatched: true,
      options: matchedChildOptions.length
        ? matchedChildOptions.map(setMatchedForChildren) : root.options
    };
  }

  return null;
};

export function searchTree(node, search = '') {
  return dfs(node, search) || [];
}

export const flattenTree = (node) => {
  const flatArray = [];

  const dfsTree = (nodes) => {
    // Push the current node (excluding its children)
    const { options, label, value } = nodes;
    flatArray.push({ label, value });

    // If the node has children, recurse through them
    if (options?.length) {
      options.forEach((child) => dfsTree(child));
    }
  };

  dfsTree(node);

  return flatArray;
};

export function findNodesAndCollectPairs(data, searchValues) {
  const result = [];

  // eslint-disable-next-line no-shadow
  function collectAllPairs(node, result) {
    result.push({ label: node.label, value: node.value });
    if (node.options) {
      node.options.forEach((option) => collectAllPairs(option, result));
    }
  }
  function collectPairs(node) {
    if (searchValues.includes(node.value)) {
      collectAllPairs(node, result);
    }
    if (node.options) {
      node.options.forEach((option) => collectPairs(option));
    }
  }
  data.forEach((node) => collectPairs(node));

  return result;
}
